/* eslint-disable */
import React, { useEffect, useState } from "react";
import LoginPage from "./components/Login";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import Verification from "./components/Verify";
import RegisterPage from "./components/Register";
import Home from './components/Home'
import Forget from "./components/Forget";
import Reset from "./components/Reset";

const Routes = () => {

  const routes = useRoutes([
    { path: '/', element: <LoginPage /> },
    { path: '/register', element: <RegisterPage /> },
    { path: '/otp', element: <Verification /> },
    { path: '/home', element: <Home /> },
    { path: '/forgot-password', element: <Forget /> },
    { path: '/reset/:userId/:token', element: <Reset /> }
  ])
  return routes
}
const App = () => {

  const [isLogin, setIsLogin] = useState(null)
  useEffect(() => {
    return () => {
      setIsLogin(localStorage.getItem('token'))
    }
  }, [])
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Routes />
      </QueryParamProvider>
    </BrowserRouter>
  )
}
export default App