/* eslint-disable */
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'
const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const Home = () => {
    const [user, setUser] = useState()
    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    let [query, setQuery] = useQueryParams({
        redirect: StringParam
    })
    const { redirect } = query

    useEffect(() => {
        let email = localStorage.getItem('email')
        async function fetcher() {
            return await fetch(`${BACKEND_URL}/cookies`, {
                method: 'post', body: JSON.stringify({ email: email }), credentials: 'include', headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()).then(res => {
                setUser(res.User)
                setName(res.User.name);
                setRole(res.User.role)
            })
        }
        let token = fetcher()

        if (!user || token.success == false || user.verified == false) {

        }

        setName(user?.name)
        setRole(user?.role)
    }, [])
    return (
        <div style={{ height: '100%', width: '100%' }} className='mx-auto flex flex-col items-center align-middle'>
            <div className='m-auto'>
                <h1 className='text-center'>Welcome, <strong>{name}</strong></h1>
                <p className='text-center align-middle'> You are a <strong>{role}</strong></p>
                <p className=' text-center align-middle '>This is an example page to test out the functionality of the website</p>
            </div>
        </div>
    )
}
export default Home