/* eslint-disable */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from 'react-modal';
import { customStyles } from "../modalstyle";
import toast, { Toaster } from 'react-hot-toast';
import HashLoader from "react-spinners/HashLoader";

const HOST_URL = process.env.REACT_APP_HOST_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const Reset = () => {

  const { userId, token } = useParams()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  let [color, setColor] = useState("#4292cf");

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    fetch(`${BACKEND_URL}/password-reset/${userId}/${token}`,
      { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ password: password, confirmPassword: confirmPassword }) }).then((response) => response.json())
      .then((res) => {
        setIsLoading(false)
        if (res.success) {
          toast.success(res.message)
          window.location.href = `${HOST_URL}`
        }
      })
      .catch( e => {
        setIsLoading(false)
        toast.error(e.response.data.message)
      })
  }

  return (
    <div className="flex flex-col p-8">
       <Toaster />
      <div className="mx-auto">
        <img src={require('../images/logo.jpg')} alt="logo" className="h-10 w-10 inline-block mx-auto" />
        <span className="font-bold">Apars Classroom</span>
      </div>
      <form className="m-auto" onSubmit={handleSubmit}>
        <div className="m-auto">
          <input type="password" placeholder="New Password" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-80 p-2 my-4" value={password} onChange={(e) => { setPassword(e.target.value) }} />

          <input type="password" placeholder="Confirm Password" className="bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400 focus:border-blue-400 block w-80 p-2 my-4" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
          <button type="submit" className="bg-blue-400 text-center w-80 px-4 py-2 rounded-lg hover:ring hover:ring-blue-500 hover:shadow-2xl">Reset</button>
        </div>
      </form>
      <Modal
        isOpen={isLoading}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="mx-auto my-auto">
          <HashLoader
            color={color}
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </Modal>
    </div>
  )
}

export default Reset